<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
    page() {
        return {
            title: this.$t("device.button.stockbind"),
            meta: [{ name: "description" }],
        };
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Starter page",
            items: [
                {
                    text: "Utility",
                    href: "/",
                },
                {
                    text: "Starter page",
                    active: true,
                },
            ],

            ismobile: false,
            activeNames: '',
            activeapp: [],
            loading: true,
            enable: false,
            group: [],
            devicelist: [],
        };
    },
    mounted() {
        this.title = this.$t("menuitems.devices.group");

        this.items = [
            {
                text: this.$t("menuitems.devices.text"),
                href: "/",
            },
            {
                text: this.$t("menuitems.devices.group"),
                active: true,
            },
        ];

        this.userinfo = JSON.parse(localStorage.getItem("user"));
        let flag = navigator.userAgent.match(
            /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
        if (flag) {
            this.ismobile = true;
        }
        this.getauthstatus();
        this.getdevicelist();
        this.getGroup();
    },
    methods: {
        getdevicelist() {
            var that = this;
            that.$axios
                .post(
                    that.apiuri,
                    {
                        action: "deviceslist",
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.loading = false;
                    that.devicelist = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getauthstatus() {
            var that = this;
            that.$axios
                .post(
                    that.apiuri,
                    {
                        action: "getauthstatus",
                        app: "device_group",
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.enable = response.data.lock;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        handleChange(val) {
            // console.log(val);
        },
        getGroup() {
            var that = this;
            that.$axios
                .post(
                    that.apiuri,
                    {
                        action: "getgroup",
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.loading = false;
                    if(response.data.status === 200){
                        that.group = response.data.data;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        addGroup() {
            this.group.push({ name: '', devices: [] });
        },
        delGroup(idx) {
            this.group.splice(idx, 1);
        },
        saveGroup() {
            var that = this;
            for (let i = 0; i < that.group.length; i++) {
                if (that.group[i].devices.length === 0 || that.group[i].name === '') {
                    that.$message.error(that.$t('device.group.error'));
                    return;
                }
            }
            that.$axios
                .post(
                    that.apiuri,
                    {
                        action: "addgroup",
                        group: that.group,
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    if (response.data.status == 200) {
                        that.$message({
                            message: that.$t('device.group.success'),
                            type: 'success'
                        });
                        that.getGroup();
                    } else {
                        that.$message.error(that.$t('device.group.error'));
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body" v-if="!enable">
                        <el-empty :description="$t('device.stock.stocknosupport')"></el-empty>
                    </div>
                    <div class="card-body" v-else>
                        <p>
                            <el-button type="primary" @click="addGroup">{{ $t("device.group.addgroup") }}</el-button>
                        </p>

                        <el-collapse v-model="activeNames" @change="handleChange" accordion v-if="group.length > 0">
                            <el-collapse-item v-for="(val, idx) in group"
                                :title="val.name + $t('device.group.total', { device: group[idx].devices.length })"
                                :name="idx" :key="idx">
                                <el-form :ref="'devices_list_' + idx" label-width="80px">
                                    <el-form-item :label="$t('device.group.name')">
                                        <el-input v-model="val.name"></el-input>
                                    </el-form-item>
                                    <el-form-item :label="$t('device.group.devices')">
                                        <el-select v-model="val.devices" multiple filterable>
                                            <el-option key="0" value="0"
                                                :label="$t('device.stock.noselect')"></el-option>
                                            <el-option v-for="item in devicelist" :key="item.edid" :label="item.name"
                                                :value="item.ED_id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="danger" @click="delGroup(idx)">{{
                                            $t('device.group.deletegroup') }}</el-button>
                                    </el-form-item>
                                </el-form>
                            </el-collapse-item>
                        </el-collapse>
                        <div v-else>
                            <el-empty :image-size="80" :description="$t('device.error.nodata')"></el-empty>
                        </div>
                        <p style="margin-top: 15px;">
                            <el-button type="primary" @click="saveGroup" :disabled="!(group.length > 0)">{{
                                $t('marketing.billing.save') }}</el-button>
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>