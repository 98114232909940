<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
    page() {
        return {
            title: this.title,
            meta: [{ name: "description" }],
        };
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "",
            items: [],
            loading: false,
            messagelist: [],
            page: 1,
            userinfo: [],
            limit: 20,
            messagetotal: 0,
            tabs: 'log',
            devices_list: [],
            log_form: {
                action: "getcommandlogs",
                date: [],
                device: ''
            },
            log_list: [],
            command_loading: false,
            console_form: {
                device: '',
                type: '',
                command: '',
                net_type: 2,
                pulse: 0
            },
            console_type: [
                {
                    type: "at_command",
                    name: this.$t("console.type.at_command")
                },
                {
                    type: "net_type",
                    name: this.$t("console.type.net_type")
                },
                {
                    type: "pulse",
                    name: this.$t("console.type.pulse")
                }
            ],
            net_type: [
                {
                    id: 0,
                    name: this.$t("console.net_type.local")
                },
                {
                    id: 1,
                    name: this.$t("console.net_type.wlan")
                },
                {
                    id: 2,
                    name: this.$t("console.net_type.wlan")
                },
                {
                    id: 3,
                    name: this.$t("console.net_type.gprs")
                }
            ]
        };
    },
    mounted() {
        this.title = this.$t("menuitems.console.text");
        this.items = [
            {
                text: this.$t("dashboard.text"),
                href: "/",
            },
            {
                text: this.$t("menuitems.console.text"),
                active: true,
            },
        ];
        this.userinfo = JSON.parse(localStorage.getItem("user"));
        //   this.page.title=this.$t('global.notice.type.notify')
        this.getdevice_list();

        this.log_form.date = [
            new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0],
            new Date().toISOString().split('T')[0]
        ]
    },
    methods: {
        switchdatatype() {

        },
        searchCommandLog() {
            var that = this;
            that.command_loading = true
            that.$axios
                .post(
                    that.apiuri,
                    that.log_form,
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.command_loading = false
                    that.log_list = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getdevice_list() {
            var that = this;
            that.$axios
                .post(
                    that.apiuri,
                    {
                        action: "getdevicelist"
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.devices_list = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');  // 月份从0开始，需要加1
            const day = String(date.getDate()).padStart(2, '0');  // 确保日期为两位数
            return `${year}-${month}-${day}`;
        },
        getPreviousDate() {
            const today = new Date();
            today.setDate(today.getDate() - 1);  // 将日期减去一天
            return today;
        },
        sendCommand() {
            var that = this;
            that.console_form.action = "sendconsolecommand";
            that.$axios
                .post(
                    that.apiuri, that.console_form,
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    if (response.data.status == 200) {
                        that.$message({
                            message: that.$t("global.success"),
                            type: "success",
                        });
                    } else {
                        that.$message.error(response.data.msg || (that.$t("global.delete.500")));
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body" v-loading="loading">
                        <el-tabs v-model="tabs" @tab-click="switchdatatype">
                            <el-tab-pane :label="$t('menuitems.console.console')" name="console">
                                <el-form ref="form" v-model="console_form" label-width="80px">
                                    <el-form-item :label="$t('console.device.text')">
                                        <el-select filterable v-model="console_form.device">
                                            <el-option v-for="item in devices_list" :key="item.ED_id" :label="item.name"
                                                :value="item.ED_id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item :label="$t('console.command.type')" v-if="console_form.device">
                                        <el-select v-model="console_form.type">
                                            <el-option v-for="item in console_type" :key="item.type" :label="item.name"
                                                :value="item.type"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item :label="$t('console.console.command')"
                                        v-if="console_form.type == 'at_command'">
                                        <el-input v-model="console_form.command" placeholder="请输入内容"></el-input>
                                    </el-form-item>
                                    <el-form-item :label="$t('console.command.net_type')" v-if="console_form.type == 'net_type'">
                                        <el-select v-model="console_form.net_type">
                                            <el-option v-for="item in net_type" :key="item.id" :label="item.name"
                                                :value="item.id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item :label="$t('console.console.pulse')"
                                        v-if="console_form.type == 'pulse'">
                                        <el-input v-model="console_form.pulse" placeholder="请输入内容"></el-input>
                                    </el-form-item>
                                    
                                    <el-form-item v-if="console_form.device">
                                        <el-button type="primary" @click="sendCommand">{{
                                            $t("console.console.send")
                                            }}</el-button>
                                    </el-form-item>
                                </el-form>

                            </el-tab-pane>
                            <el-tab-pane :label="$t('menuitems.console.logs')" name="log">
                                <el-form ref="form" v-model="log_form" :inline="true" label-width="80px">
                                    <el-form-item :label="$t('statistics.sales.daily.v2')">
                                        <el-date-picker v-model="log_form.date" type="daterange" align="right"
                                            unlink-panels :range-separator="$t('statistics.sales.month.to')"
                                            :start-placeholder="$t('statistics.sales.daily.start')"
                                            :end-placeholder="$t('statistics.sales.daily.end')"
                                            value-format="yyyy-MM-dd">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item :label="$t('console.device.text')">
                                        <el-select filterable v-model="log_form.device">
                                            <el-option v-for="item in devices_list" :key="item.ED_id" :label="item.name"
                                                :value="item.ED_id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="searchCommandLog">{{
                                            $t("statistics.sales.search")
                                            }}</el-button>
                                    </el-form-item>
                                </el-form>
                                <el-table :data="log_list" style="width: 100%" v-loading="command_loading">

                                    <el-table-column prop="data" :label="$t('console.device.command')">
                                    </el-table-column>
                                    <el-table-column prop="time" :label="$t('console.device.time')" width="180">
                                    </el-table-column>
                                    <el-table-column prop="device" :label="$t('console.device.id')" width="150">
                                    </el-table-column>
                                    <el-table-column prop="type" :label="$t('console.device.type')" width="80">
                                    </el-table-column>
                                    <el-table-column prop="action" :label="$t('console.device.action')" width="150">
                                    </el-table-column>

                                </el-table>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>